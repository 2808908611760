import { Navigate, Route, Routes } from 'react-router-dom';
import PrivacyPolicyPage from './pages/privacy/PrivacyPolicyPage';
import UnderConstruction from './pages/under-construction/UnderConstruction';

export interface AppRoutesProps {}

export const AppRoutes = ({}: AppRoutesProps) => {
  return (
    <Routes>
      <Route path="" element={<UnderConstruction />} />
      <Route path="privacy-policy" element={<PrivacyPolicyPage />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
