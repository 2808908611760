import { ThemeService } from '@jtjs/core-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import './styles/main.scss';

ThemeService.registerTheme({
  name: 'light',
  accent: '#e0672b',
  background: '#fff',
  button: '#d4ac3f',
  buttonText: '#fff',
  disabled: '#7d7d7d',
  focus: '#679ceb',
  foreground: '#f5f5f5',
  outline: '#d1d1d1',
  text: '#333',
});

ThemeService.registerTheme({
  ...ThemeService.defaultTheme,
  name: 'dark',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
