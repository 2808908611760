import { Card, Flexbox, Heading, Link, Text } from '@jtjs/react';
import Logo from '../../common/Logo';

export interface PrivacyPolicyPageProps {}

export const PrivacyPolicyPage = ({}: PrivacyPolicyPageProps) => {
  return (
    <Flexbox horizontalAlignment="center" direction="column" spacing="3rem" style={{ padding: '1rem' }}>
      <Logo />

      <Card
        style={{
          maxWidth: 1200,
        }}
      >
        <Heading importance={1}>Privacy Policy</Heading>

        <Text>
          We like privacy. We don't like people knowing everything about our personal selves, and we think you deserve
          the same courtesy. However, some channels we use do gather some information, and our apps may gather
          information you provide as well (to make accounts, for example). On our mobile apps, the Google Play Store
          tracks basic system information about your phone (like your OS version), though nothing it gathers and sends
          to us is personally identifiable. Look at{' '}
          <Link href="https://play.google.com/about/play-terms/index.html">Google's Terms of Service</Link> if you're
          curious (the privacy bit's in there). We use the information gathered in any of our apps to aid in bug-fixing,
          to give us statistical data on our user base, and provide a better user experience. We do not share or sell
          your information.
        </Text>

        <Text>
          Sometimes, if an application crashes it might generate details about your system to aid in addressing the
          problem that caused the crash. This information is only sent to Sandware if you allow it to be sent. You, the
          user, will be prompted whether you would like to send us the crash information. If you choose not to, we will
          not see the information, though a data log containing system information may still be generated and stored
          locally on your machine. You may do whatever you like with this data, though you may not modify the data and
          then share the modified data with Sandware. The data is not shared with Sandware unless you expressly choose
          to share it.
        </Text>

        <Text>
          Some of our apps store information you provide on a server so you can retrieve it with a valid login on any
          compatible device. We do not share or sell this information, and it doesn't leave the servers we use. The data
          you provide that we store is encrypted in transit.
        </Text>

        <Text>
          This policy is subject to change, though our respect for your privacy will always endure. Please check back
          regularly.
        </Text>
      </Card>
    </Flexbox>
  );
};

export default PrivacyPolicyPage;
