import { Flexbox, Heading, Text } from '@jtjs/react';
import Logo from '../../common/Logo';

export interface UnderConstructionProps {}

export const UnderConstruction = ({}: UnderConstructionProps) => {
  return (
    <Flexbox className="under-construction" direction="column" horizontalAlignment="center" verticalAlignment="center">
      <Heading importance={1}>Please Excuse the Dust</Heading>

      <Flexbox className="info-container" direction="column" horizontalAlignment="center">
        <Logo />

        <Text>We're building something great</Text>
      </Flexbox>
    </Flexbox>
  );
};

export default UnderConstruction;
